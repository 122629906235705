import React, { useState, useEffect  } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';
import { getAddress, signMessage, sendBtcTransaction, signTransaction, signMultipleTransactions  } from 'sats-connect';
import * as btc from 'micro-btc-signer';
import { hex, base64 } from '@scure/base';
import { InscriptionParserService } from 'ordpool-parser';
import { HashLoader } from 'react-spinners';
/* global BigInt */

function App() {
 
  const [userAddresses, setUserAddresses] = useState({ ordinal: '', payment: '', ordinalKey:'' });
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [utxos, setUtxos] = useState([]);
  const [utxosSmol, setUtxosSmol] = useState([]);
  const [selectedUtxos, setSelectedUtxos] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [approxSats, setApproxSats] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [feerate, setFeerate] = useState(0);
  const [broadcastTxids, setBroadcastTxids] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [showInfoPopup, setShowInfoPopup] = useState(false);



  const networkConfig = JSON.parse(process.env.REACT_APP_NETWORK_CONFIG);

/* network defined in ENV
  const bitcoinTestnet = {
    bech32: 'tb',
    pubKeyHash: 0x6f,
    scriptHash: 0xc4,
    wif: 0xef,
  };
  const bitcoinMainnet = {
    bech32:'bc',
    pubKeyHash: 0x00,
    scriptHash: 0x05,
    wif: 0x80
  };
*/
  useEffect(() => {
    window.getInscriptions = getInscriptions;
    if (isUserConnected) {
      console.log(userAddresses);

      // Now you can call processUTXOsForAddress or any other function that relies on updated userAddresses
      processUTXOsForAddress(userAddresses.ordinal);
      
    }
  }, [userAddresses, isUserConnected]);
  // useEffect to recalculate approxSats when selectedUtxos or feerate changes
  useEffect(() => {
    const newApproxSats = calculateApproxSats(selectedUtxos, feerate);
    setApproxSats(newApproxSats);
    setSelectedCount(Object.values(selectedUtxos).length);
  }, [selectedUtxos, feerate]); // Dependencies array, recalculate when these change


  const broadcastTransactions = async (txHexList) => {
    for (const txHex of txHexList) {
      let config = {method: 'post',maxBodyLength: Infinity,url: `${process.env.REACT_APP_MEMPOOLURL}/api/tx`,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data : txHex
      };
      
      try {
        
        axios.request(config)
        .then((response) => {
          const txid = JSON.stringify(response.data);
          setBroadcastTxids(prevTxids => [...prevTxids, txid]);
        })
        .catch((error) => {
          console.log(error);
        });
        
      } catch (error) {
        console.error(`Error broadcasting transaction: ${error.message}`);
        // Handle the error (optional: you could also update the state with a placeholder or error message)
      }
    }
  };

  // Function to decode base64 data URIs and ensure UTF-8 text content is correctly handled
  function decodeBase64DataUri(dataUri) {
    // Extract the base64 encoded string
    const base64Content = dataUri.split(',')[1];
    
    // Decode base64 string to a byte sequence
    const bytes = Uint8Array.from(atob(base64Content), c => c.charCodeAt(0));
    
    // Use TextDecoder to handle UTF-8 decoding
    const textDecoder = new TextDecoder('utf-8');
    return textDecoder.decode(bytes);
  }
  async function getInscriptions(txId) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MEMPOOLURL}/api/tx/${txId}`);
      const transaction = response.data;
      return InscriptionParserService.parse(transaction);
    } catch (error) {
      console.error('Error fetching transaction or parsing inscriptions:', error);
      return [];
    }
  }
  
  const handleCheckboxChange = (e, utxo) => {
    const checked = e.target.checked;
    setSelectedUtxos((prevSelectedUtxos) => {
      // Clone the current state to avoid direct mutations
      const updatedSelection = { ...prevSelectedUtxos };
      
      // Update the selection based on the checkbox state
      if (checked) {
        updatedSelection[utxo.txid] = utxo;
      } else {
        delete updatedSelection[utxo.txid];
      }
      
      return updatedSelection;
    });
  };
  // Function to calculate approximate sats
  const calculateApproxSats = (selectedUtxos, feerate) => {
    const totalSats = Object.values(selectedUtxos).reduce((total, { value }) => total + ((value - (176 * feerate) - 550) / 2), 0);
    return Math.round(totalSats); // Assuming you want to round to the nearest sat
  };

  // Function to handle fee rate change
  const handleFeeRateChange = (event) => {
    setFeerate(Number(event.target.value));
  };

  const fetchFees = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MEMPOOLURL}/api/v1/fees/recommended`);
      const { fastestFee } = response.data;
      console.log('using fee rate: '+fastestFee)
      return fastestFee;
    } catch (error) {
      console.error('Error fetching fastestFee:', error);
      // Handle the error appropriately - you might want to use a default fee rate here
      return null; // or a default fee value if you have one
    }
  };

  async function fetchUTXOs(address) {
    // Check if we are on Mainnet or Testnet
    //console.log(process.env.REACT_APP_BTCNETWORK)
  
    if (String(process.env.REACT_APP_BTCNETWORK) === 'Mainnet') {
      // Use the Mainnet API to get inscriptions
      //console.log('hello')
      let config = {
        method: 'get',
        url: `https://api.hiro.so/ordinals/v1/inscriptions?address=${address}&limit=60`,
        headers: { 
          'Accept': 'application/json',
          'x-hiro-api-key': '2fa2fc873169a8fa04b118b024a78af3'
        }
      };
  
      try {
        const response = await axios.request(config);
        const data = response.data;
        let utxos = [];
        
        // Calculate total pages needed to fetch all inscriptions
        const totalPages = Math.ceil(data.total / data.limit);
  
        for (let page = 1; page <= totalPages; page++) {
          // Fetch each page if more than 1 page is needed
          if (page > 1) {
            config.url = `https://api.hiro.so/ordinals/v1/inscriptions?address=${address}&limit=60&page=${page}`;
            const pageResponse = await axios.request(config);
            data.results = [...data.results, ...pageResponse.data.results];
          }
        }
        console.log('Found '+ data.results.length+ ' inscriptions')
        // Convert each inscription to UTXO format
        utxos = data.results.map(inscription => {
          const [txid, vout] = inscription.output.split(':');
           
          return {
            txid: txid,
            vout: parseInt(vout, 10),
            value: parseInt(inscription.value, 10),
            inscriptionID: inscription.id,
            genesisID: inscription.genesis_tx_id
          };
        });
        
        return utxos;
      } catch (error) {
        console.error('Error fetching inscriptions:', error);
        return [];
      }
    } else {
      // Existing Testnet UTXO fetch logic here
      try {
        const response = await axios.get(`${process.env.REACT_APP_MEMPOOLURL}/api/address/${address}/utxo`);
        return response.data;
      } catch (error) {
        console.error('Error fetching UTXOs:', error);
        return [];
      }
    }
  }

  //no longer used, using ordpoolparser library instead
  async function checkForOrdinalMarker(txid) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MEMPOOLURL}/api/tx/${txid}/hex`);
      return response.data.includes('6f7264');
    } catch (error) {
      console.error('Error checking for ordinal marker:', error);
      return false;
    }
  }

  async function processUTXOsForAddress(address) {
    setIsLoading(true);
    const fetchedUTXOs = await fetchUTXOs(address);
    let inscriptions = []
    const ordinalsUTXOs = [];
    const ordinalsUTXOsSmol = [];

    for (const utxo of fetchedUTXOs) {
      console.log(utxo)
      if (parseInt(utxo.value) < 1000){
        //const hasOrdinalsmol = await checkForOrdinalMarker(utxo.txid);
        //if (hasOrdinalsmol) {
        //  ordinalsUTXOsSmol.push(utxo);
        //}
        console.log('Found inscription with only ' +utxo.value + ' sats');
        continue;
      }
      if(utxo.inscriptionID){
        inscriptions = await getInscriptions(utxo.genesisID);
      }
      else{
        inscriptions = await getInscriptions(utxo.txid);
      }
      if (inscriptions.length > 0) {
        // Attach data URI to UTXO if an inscription is found
        utxo.data = inscriptions[0].getDataUri();
        ordinalsUTXOs.push(utxo);
      }
      
    }

    setUtxos(ordinalsUTXOs); // Update state with filtered UTXOs
    setUtxosSmol(ordinalsUTXOsSmol);
    setIsLoading(false);
  }

/* no longer being used
  const handleRecycleClick = (utxo) => {
    
    const output = {
      txid: utxo.txid,
      vout: utxo.vout,
      value: utxo.value,
    };
    const internalPubKeyHex = userAddresses.ordinalKey;
    const recipientAddress = userAddresses.ordinal;
    const changeAddress = userAddresses.payment;

    const psbtB64 = createRecyclePSBT(output, internalPubKeyHex, recipientAddress, changeAddress);
    console.log(psbtB64);
    // Additional handling for PSBT (e.g., display or send to server)
  };
*/

  const handleConnectClick = async () => {
    let getfeerate = await fetchFees();
    const getAddressOptions = {
      payload: {
        purposes: ['ordinals', 'payment'],
        message: 'We need your address to connect your wallet for receiving Ordinals and payments.',
        network: {
          type: process.env.REACT_APP_BTCNETWORK,
        },
      },
      onFinish: (response) => {
        // Assuming the response includes an array of addresses
        console.log(response)
        const ordinalAddress = response.addresses.find(addr => addr.purpose === 'ordinals');
        const paymentAddress = response.addresses.find(addr => addr.purpose === 'payment');
        setUserAddresses({
          //ordinal: 'bc1ptvgn2xefn9q6tetelal9s6l5tp6ed9dy9vu6kk7z74mfytxdm0fq297qf6',
          ordinal: ordinalAddress.address,
          payment: paymentAddress.address,
          ordinalKey: ordinalAddress.publicKey
        });
        setIsUserConnected(true);
        
        setFeerate(getfeerate);
        
        //processUTXOsForAddress(userAddresses.ordinal);
        
      },
      onCancel: () => {
        alert('Connection request canceled by user.');
      },
    };
    try {
      await getAddress(getAddressOptions);
    } catch (error) {
      console.error('Error connecting to wallet:', error);
      // Optionally handle any errors (e.g., show an error message to the user)
    }
  };

  // Function to create and return a base64 encoded PSBT for a Taproot (P2TR) transaction
  const createRecyclePSBT = async (output, ordinalPubKey, ordinalsAddress, changeAddress) => {
  // Decode the internal public key from hex
  
  console.log('output:'+output);
  console.log('pubkey:'+ordinalPubKey);
  console.log('ord address:'+ordinalsAddress);
  console.log('change address:'+changeAddress);
  console.log('fee rate:'+feerate)
  const internalPubKey =   hex.decode(ordinalPubKey)
  const p2tr = btc.p2tr(internalPubKey, undefined, networkConfig);

  // Initialize a new transaction
  let tx = new btc.Transaction(networkConfig);

  // Add the input
  tx.addInput({
    txid: output.txid,
    index: output.vout,
    witnessUtxo: {
      script: p2tr.script,
      amount: output.value,
    },
    tapInternalKey: internalPubKey,
    sighashType: btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY,
  });

  // Add the recipient and change outputs
  // calculate vbytes size vBytes = inputs * 68.5 + outputs * 31 + 10 and then multiple by fee rate to get sat fees. 176 for 1 input and 3 outputs
  const totalSats = output.value;
  const relaySats = 176 * feerate;
  const ordinalSats = 550;
  const recycleSats = (totalSats - relaySats - ordinalSats)*.5;
  const feeSats = (totalSats - relaySats - ordinalSats)*.5;
  const feeAddress = `${process.env.REACT_APP_FEE_ADDRESS}`;
  console.log('relay sats:'+relaySats);
  console.log('fee sats:'+feeSats);
  console.log('ordinal sats:'+ordinalSats);

  tx.addOutputAddress(ordinalsAddress, BigInt(ordinalSats), networkConfig);
  tx.addOutputAddress(feeAddress, BigInt(feeSats), networkConfig);
  tx.addOutputAddress(changeAddress, BigInt(recycleSats), networkConfig);

  // Generate the base64 encoded PSBT
  const psbt = tx.toPSBT(0);
  const psbtB64 = base64.encode(psbt);

  return psbtB64;
  {/*
  const signPsbtOptions = {
    payload: {
      network: {
        type:'Testnet'
      },
      psbtBase64: psbtB64,
      broadcast: true,
      inputsToSign: [{
          address: ordinalsAddress,
          signingIndexes: [0],
          sigHash: 131
      }],
    },
    onFinish: (response) => {
      console.log(response.psbtBase64)
    },
    onCancel: () => alert('Canceled'),
  }
  
  let signedpsbt = await signTransaction(signPsbtOptions);
  console.log(signedpsbt);
  //const signedPSBTdecoded = base64.decode(signedpsbt);
  //const signedPSBTHex = hex.encode(signedpsbt)
  //console.log(signedPSBTHex);
  return signedpsbt;
*/}

}
  const preparePSBTs = async () => {
    const psbtsBase64 = [];
    const inputsToSign = [];

    for (const utxo of Object.values(selectedUtxos)) {
      const output = {
        txid: utxo.txid,
        vout: utxo.vout,
        value: utxo.value,
      };
      const internalPubKeyHex = userAddresses.ordinalKey;
      const recipientAddress = userAddresses.ordinal;
      const changeAddress = userAddresses.payment;

      // Assuming createRecyclePSBT now only creates and returns the PSBT without signing it
      const psbtB64 = await createRecyclePSBT(output, internalPubKeyHex, recipientAddress, changeAddress);
      psbtsBase64.push(psbtB64);

      // Assuming each UTXO has a corresponding input to sign at index 0
      // Adjust this as necessary based on your transaction construction
      inputsToSign.push({
        address: recipientAddress,
        signingIndexes: [0], // Assuming the UTXO to sign is always at index 0 for simplicity
        sigHash: btc.SignatureHash.SINGLE | btc.SignatureHash.ANYONECANPAY | 131
      });
    }

    return { psbtsBase64, inputsToSign };
  };
  const handleRecycleSelected = async () => {
    
    const finalizedTxHexes = [];
    const { psbtsBase64, inputsToSign } = await preparePSBTs();
    console.log(psbtsBase64)
    console.log(inputsToSign)
    if (psbtsBase64.length === 1) {
      // Single PSBT, use signTransaction
      await signTransaction({
        payload: {
          network: {
            type: process.env.REACT_APP_BTCNETWORK,
          },
          psbtBase64: psbtsBase64[0],
          broadcast: false,
          inputsToSign: [inputsToSign[0]],
        },
        onFinish: (response) => {
          setIsPopupVisible(true);
          console.log('Single tx signing response:', response);
          // Handle successful signing
          console.log(response.psbtBase64);
          const mypsbt = base64.decode(response.psbtBase64);
          
          const tx7 = btc.Transaction.fromPSBT(mypsbt);
          tx7.finalize();
          //const psbt7 = tx7.toPSBT();
          //const tx8 = btc.Transaction.fromPSBT(psbt7);
          const txHex = tx7.hex;
          finalizedTxHexes.push(txHex);
          //console.log(tx8.hex);
          //console.log(hex.encode(tx8.extract()))
          broadcastTransactions(finalizedTxHexes).then(broadcastResults => {
            console.log('Broadcast Results:', broadcastResults);
            // Here, broadcastResults could be an array of txids or status messages from your broadcast function
          });

        },
        onCancel: () => alert("Canceled"),
      });
    } else if (psbtsBase64.length > 1) {
      // Multiple PSBTs, use signMultipleTransactions
      await signMultipleTransactions({
        payload: {
          network: {
            type: process.env.REACT_APP_BTCNETWORK,
          },
          message: "Sign Transaction",
          psbts: psbtsBase64.map((psbtBase64, index) => ({
            psbtBase64,
            inputsToSign: [inputsToSign[index]] // Map inputsToSign accordingly
            
          })),
          
        },
        onFinish: (response) => {
          setIsPopupVisible(true);
          console.log('Bulk tx signing response:', response);
          
          for (const item of response) {
            try {
              // Decode the base64 PSBT
              const mypsbt = base64.decode(item.psbtBase64);
              const tx7 = btc.Transaction.fromPSBT(mypsbt);  
              tx7.finalize();
              
              // Extract the transaction and convert to hex
              const txHex = tx7.hex
              
              // Add the hex to the array
              finalizedTxHexes.push(txHex);
            } catch (error) {
              console.error('Error processing PSBT:', error);
              // Optionally handle the error, such as pushing a null or error message to `finalizedTxHexes`
            }
          }
    
          console.log('Finalized Transaction Hexes:', finalizedTxHexes);
          broadcastTransactions(finalizedTxHexes).then(broadcastResults => {
            console.log('Broadcast Results:', broadcastResults);
            // Here, broadcastResults could be an array of txids or status messages from your broadcast function
          });

          // Handle successful bulk signing
        },
        onCancel: () => alert("Canceled"),
      });
    }
  
    // Clear selected UTXOs after processing
    processUTXOsForAddress(userAddresses.ordinal);
    setSelectedUtxos({});
    
  };
  

  return (
    <div id="top">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img src="satcycla.png" alt="Logo" width="69" className="d-inline-block align-top" />
              </a>
              <div className="d-flex">
              <div className="navbar-icon" onClick={() => setShowInfoPopup(true)}>
                <i className="fas fa-question-circle"></i> {/* Example using FontAwesome */}
              </div>

              <a href="https://x.com/inscribetoday" target="blank" className="me-2" ><i className="fab fa-twitter"></i></a>
                  <a href="https://discord.gg/RGjXgW7nEE" target="blank" className="me-2"><i className="fab fa-discord"></i></a>
                {!isUserConnected ? (
                  <button className="btn btn-primary" onClick={handleConnectClick}>Connect</button>
                ) : (
                  // Use a button or a stylized span for the connected state, making it clickable to reconnect
                  <button className="btn btn-outline-secondary" onClick={handleConnectClick}>
                    {`Connected: ${userAddresses.ordinal.substring(0, 4)}...${userAddresses.ordinal.slice(-4)}`}
                  </button>
                )}
                
              </div>
            </div>
        </nav>
        <div className="hero-section text-center" style={{ position: 'relative' }}>
          
            {showInfoPopup && (
              <div className="info-popup">
                <div className="info-popup-content">
                  <h2>Satcycla</h2>
                  <p>Satcycla allows inscription holders to release their extra sats padded on early inscriptions. It does this by splitting your ordinal UTXOs into two parts.</p>
                  <ul>
                    <li>Use this tool at your own risk. Try one before doing many</li>
                    <li>If you have grails or other high value inscriptions, while this tool will work, you're probably better off putting those in a vault</li>
                    <li>This tool is designed for outputs holding a single inscription.</li>
                    <li>The number of sats you'll receive depends heavily on the fee rate, so recycle when fees are low.</li>
                  </ul>
                  <button className="btn btn-outline-secondary" onClick={() => setShowInfoPopup(false)}>Close</button>
                </div>
              </div>
            )}
            {!isUserConnected && (
                <img src="connect.png" alt="Hero" className="img-fluid" onClick={handleConnectClick} />
            )}
            {isUserConnected && (
            <>
              {isLoading && (
                <div className="header-with-loader" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <h1 className="text mt-4" style={{ marginRight: '10px' }}>Available Ords</h1>
                  <HashLoader size={15} color={"#f507b5"} loading={isLoading} />
                </div>
              )}
              {!isLoading && utxos.length === 0 && (
                <div>
                  <img src="no-ords.png" alt="No UTXOs" className="img-fluid" />
                  <h3 className="text mt-4" >No inscriptions with more than 1000 sats found</h3>
                </div>
              )}
              {!isLoading && utxos.length > 0 && (
                <>
                  <div className="header-with-loader" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h1 className="text mt-4" style={{ marginRight: '10px' }}>Available Ords</h1>
                  </div>
                  <div className="cards-container">
                    {utxos.map((utxo) => (
                      <div key={utxo.txid} className="card">
                        <div className="card-content">
                          {utxo.data && (utxo.data.startsWith('data:text') || utxo.data.startsWith('data:application/json')) && (
                            <p>{decodeBase64DataUri(utxo.data)}</p> // Display text content
                          )}
                          {utxo.data && utxo.data.startsWith('data:image') && (
                            <img src={utxo.data} alt="Inscription Thumbnail" /> // Display image thumbnail
                          )}
                          {/* Additional handlers for other MIME types */}
                          {utxo.data && !utxo.data.startsWith('data:text') && !utxo.data.startsWith('data:image') && !utxo.data.startsWith('data:application/json') && (
                            // Fallback for unhandled MIME types
                            <div>
                              <p>Unsupported MIME type, click below to view.</p>
                              <button onClick={() => window.open('https://ordinals.com/inscription/'+utxo.inscriptionID, '_blank')}>View Content</button>
                            </div>
                          )}
                        </div>
                        <div className="card-details">
                          <input
                            type="checkbox"
                            id={utxo.txid}
                            checked={!!selectedUtxos[utxo.txid]}
                            onChange={(e) => handleCheckboxChange(e, utxo)}
                          />
                          <label htmlFor={utxo.txid} style={{ display: 'block', marginTop: '10px' }}>TXID: {utxo.txid.substring(0, 4)}...{utxo.txid.substring(utxo.txid.length -4)}</label>
                          <label htmlFor={utxo.txid}>Value: {utxo.value} sats</label>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {isPopupVisible && (
                <div className="popup">
                  <div>
                    {broadcastTxids.map((txid, index) => (
                      
                      <p key={index}>Broadcasted Transaction ID: 
                      <a href={`${process.env.REACT_APP_MEMPOOLURL}/tx/${txid.replace(/"/g, '')}`} target="_blank" rel="noopener noreferrer">
                          {txid.replace(/"/g, '')}
                      </a>
                      </p>
                    ))}
                  </div>
                  <button className="btn btn-outline-secondary" onClick={() => setIsPopupVisible(false)}>Close</button>
                </div>
              )}
            </>
            )}
        </div>
        
        <div className="sticky-bar">
        
          <p>Ordinals Selected: {selectedCount}</p>
          <p>~ sats you receive: {approxSats}</p>
          
          <div className="fee-rate-slider">
            <label htmlFor="feeRate">Fee Rate: {feerate} sat/vByte</label>
            <input
              id="feeRate"
              type="range"
              min="1" // Minimum fee rate
              max="200" // Maximum fee rate, adjust based on typical range
              value={feerate}
              onChange={handleFeeRateChange}
              step="1" // Step value for the slider
            />
          </div>
          <button className="btn btn-primary" onClick={handleRecycleSelected} disabled={selectedCount === 0}>Recycle</button>
        </div>
      </div>
    </div>
    
  );
}

export default App;
